.singleError-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 16px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.ticketFrame {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    flex: none;
    flex-grow: 0;
    flex-wrap: wrap;
}

.left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex: none;
    flex-grow: 0;
}

.right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex: none;
    flex-grow: 0;
}

.leftCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 840px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    flex-grow: 1;
}

.leftCardHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 851px;
    height: 56px;
    background: #FFFFFF;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.leftCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 851px;
    height: fit-content;
    background: #FFFFFF;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.headTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 851px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.brief {
    margin-bottom: 0px;
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    flex-grow: 0;
}

.leftCardBodyFrame {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 803px;
    height: fit-content;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.titleDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    flex-grow: 0;
}

.titleText {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    flex-grow: 0;
}

.titleTextContent {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    flex-grow: 0;
}

.typeDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    flex-grow: 0;
}

.type {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    flex-grow: 0;
}

.typeContent {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.descDiv,
.skuDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.description,
.skuDesc {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.descriptionContent,
.skuContent {
    width: 803px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.rightCard {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 340px;

    background: #FFFFFF;

    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.rightCardHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 340px;
    height: 56px;
    background: #FFFFFF;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.rightHeadTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    width: 340px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.detailWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.detailText {
    height: 24px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.idWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    height: 22px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.idText {
    height: 22px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0F2A6F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.rightCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 340px;
    background: #FFFFFF;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.rightCardBodyFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 100%;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.block1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 22px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
}

.statusText {
    width: 41px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.block2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0;
}

.submittedText {
    width: 80px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.dateText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: end;
}

.block3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 24px;

    flex: none;
    order: 2;
    flex-grow: 0;
}

.block4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px;
    width: 100%;
    flex: none;
    order: 3;
    height: auto;
    flex-grow: 0;
}

.priorityText {
    width: 45px;
    height: 22px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.priorityClr {
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.highClr {
    color: #F5222D;
}

.lowClr {
    color: #52C41A;
}

.mediumClr {
    color: #FAAD14;
}

.raisedByText {
    width: 61px;
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.raisedByName {
    height: 24px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;

    color: #0F2A6F;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.addCommentFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 851px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.commentContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 851px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.commentContentDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 851px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.textAreaStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 12px;

    width: 851px;
    height: 98px;

    background: #FFFFFF;

    border: 1px solid #D9D9D9;
    border-radius: 2px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.buttonFrame {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin-bottom: 50px;
    width: 259px;
    height: 32px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.addBtnComment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    height: 32px;

    background: #0F2A6F;

    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white;
    cursor: pointer;
}

.closeBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #FF4D4F;
    color: #FF4D4F;
    cursor: pointer;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.commentItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;

    width: 851px;
    height: fit-content;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #FFFFFF;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.commentItemFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 851px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.commentContentTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 4px;
    width: 851px;
    height: 56px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.commentAuthor {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 145px;
    height: 22px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.commentAuthorAdmin {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 188px;
    height: 22px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.authorName {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #0F2A6F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.authorTime {
    width: 52px;
    height: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #BFBFBF;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.content {
    width: 803px;
    height: 22px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.commentContentTextAdminWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 24px;
    gap: 4px;

    width: 851px;
    height: 56px;
    flex: none;
    flex-grow: 0;
}

.authorNameAdmin {
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #0F2A6F;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.authorTimeAdmin {
    width: 52px;
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #BFBFBF;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.contentAdmin {
    width: 803px;
    height: 22px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    text-align: right;
    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.errormsg {
    color: red;
    margin-left: 5px;
    margin-top: -15px;
}

.reOpenBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    cursor: pointer;
}


.newtexta {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 5px 12px;
    width: 851px;
    height: 98px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.sendmsg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 32px;
    background: #0F2A6F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

}

.adminName {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    color: #0F2A6F;
    margin-top: 10px;
}

.sendmsg:hover,
.sendmsg:active {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
    color: white;
}

.adminTime {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #BFBFBF;
    margin-top: 10px;
}

.mainbodycomment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    width: 851px;
    height: auto;
    background: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.righta {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
}

.reverseName {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 15px;
}

.sysName {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 10px;
    color: #0F2A6F;
}

.sysTime {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #BFBFBF;
    float: left;
    margin-left: 10px;
    margin-top: 10px;

}

.leftc {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
}

.adminComment {
    margin-left: 25px;
    margin-right: 25px;
    text-align: right;
}

.sysComment {
    float: right;
    margin-left: 20px;
    margin-right: 20px;
}

.divider {
    margin: 10px;
}

@media only screen and (max-width: 850px) {

    .left,
    .leftCard,
    .leftCardBody,
    .leftCardHead,
    .leftCardBodyFrame,
    .leftCardHead {
        width: fit-content;
    }

    .descDiv {
        width: 600px;
        overflow-x: scroll;
    }

    .newtexta {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .mainbodycomment {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {

    .right,
    .rightCard,
    .rightCardHead,
    .rightHeadTitleWrapper,
    .rightCardBody {
        width: auto;
    }

    .rightCardHead,
    .rightHeadTitleWrapper {
        height: auto;
    }

    .rightHeadTitleWrapper {
        flex-direction: column;
    }

    .buttonFrame {
        margin-top: 30px;
    }

    .singleError-card-body {
        padding: 0;
    }

    .titleDiv {
        width: 200px;
    }

    .typeDiv {
        margin-top: 10px;
    }

    .left,
    .leftCard,
    .leftCardBody,
    .leftCardHead {
        width: 300px;
    }

    .leftCardBodyFrame {
        width: 250px;
    }

    .descDiv {
        width: inherit;
        overflow-x: scroll;
    }

    .newtexta {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .mainbodycomment {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

@media screen and (min-device-width: 845px) and (max-device-width: 1100px) {
    .mainbodycomment {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .newtexta {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

.pNameText {
    width: 50%;
    text-align: left;
}

.pNameSubText {
    width: 50%;
    text-align: right;
}