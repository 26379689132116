.card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;
  gap: 8px;
  width: 1232px;
  height: 216px;
  flex: none;
  order: 1;
  align-self: stretch;
}

.addUserBtnProfile {
  display: flex;
}

.err {
  margin-bottom: 10px;
}

.addUserBtnProfile .ant-input-suffix {
  margin-left: 87px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 488px;
  height: 216px;
}

.ant-card-body {
  padding: 0;
}

.content-edit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 488px;
  height: 382px;
}

.label-width {
  margin-bottom: 12px;
}

.profileWholeWrapper {
  height: calc(100vh - 102px);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-edit-change {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 488px;
  height: 382px;
  margin-top: 200px;
}

.ant-card-body {
  padding: 0;
}

.extra-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 56px;
  background: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.extra-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px 24px;
  gap: 10px;
  height: 56px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.extra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 24px;
  height: 22px;
}

.text-extra-basic {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 92px;
  height: 24px;
}

.text-extra-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 192px;
  height: 24px;
}

.text-basic {
  width: 92px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.text-basic-change {
  width: 192px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.text-extra {
  width: 24px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0f2a6f;
  cursor: pointer;
}

.ant-card {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 488px;
  height: 216px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.edit {
  margin-top: 10px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 488px;
  height: 382px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.change {
  margin-top: 10px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 488px;
  height: 480px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.body-styl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  height: 160px;
  background: #ffffff;
}

.body-styl-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  width: 448px;
  height: 326px;
  background: #ffffff;
}

.body-styl-change {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  width: 448px;
  height: 360px;
  background: #ffffff;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 440px;
  height: 112px;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.frame-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 400px;
  height: 278px;
}

.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 82px;
  height: 48px;
}

.full-name {
  width: 63px;
  height: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.full-name-api {
  width: 182px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-label > label {
  width: 389px;
  height: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.error-label {
  margin-bottom: -1px;
  color: red;
}
.label-info{
      font-style: italic;
    font-size: 12px;
}
.error-label-info {
  margin-bottom: -1px;
  color: red;

}
.info-label-info {
  color: grey;
}
.success-label-info {
  color: green;
}

.ant-form-item-error {
  margin-bottom: 0px;
}

.affix-wrapperin {
  width: 440px;
}
