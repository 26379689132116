.promo-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 8px; */
  width: 2350px;
  height: 227px;
  align-self: stretch;
}

.userBtnClose {
  background: #ffffff;
}

.userErrorBtnClose .ant-drawer-close {
  color: #ffffff;
}

.promo-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  width: 2350px;
  height: 227px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}

.promo-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 100%;
  /* width: 2350px; */
  height: 88px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f0f0f0;
}

@media only screen and (min-width: 840px) {
  .page-header-promo {
    flex-direction: column;
    height: auto;
    overflow-x: auto;
  }
  .table-header{
    flex-direction: column;
    height: auto;
    /* overflow-x: auto; */
  }
}

@media only screen and (max-width: 840px) and (min-width: 550px) {
  .promo-body {
    overflow-x: auto;
    flex-direction: column;
    height: auto;
  }
  .page-header-promo {
    flex-direction: column;
    height: auto;
    overflow-x: auto;
  }
  .table-header{
    flex-direction: column;
    height: auto;
    width: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .body-right {
    width: 270px;
  }
  .promo-body {
    overflow-x: auto;
    width: 300px;
    flex-direction: column;
    height: auto;
  }
  .page-header-promo {
    flex-direction: column;
    height: auto;
    overflow-x: auto;
  }
  .table-header{
    flex-direction: column;
    height: auto;
  }
}

.body-right {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 375px;
  height: 40px;
  flex: none;
  order: 0;
}

.body-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 162px;
  height: 40px;
  flex: none;
  order: 1;
}

.importCsvBtn {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  color: black;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.addShelfBtn {
  width: 100%;
  height: 100%;
  background: #0f2a6f;
  border: 1px solid #0f2a6f;
}

.page-header-promo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  width: 100%;
  /* width: 1232px; */
  height: 137px;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
}

.heading-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px;
  gap: 16px;
  width: 106px;
  height: 34px;
  flex: none;
  order: 0;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 136px;
  height: 22px;
}

.title {
  width: 136px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.content-live {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 0px;
  width: 100%;
  /* width: 1184px; */
  height: 71px;
  flex: none;
  order: 1;
  align-self: stretch;
}

.layout-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 63px;
}

.static {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 0px 0px;
  gap: 4px;
  width: 165.33px;
  height: 63px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.static-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 126px;
  height: 22px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.static-title-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 155px;
  height: 22px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.static-title-text {
  width: 126px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
}

.static-title-text-status {
  width: 155px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
}

.static-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 69px;
  height: 37px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.static-value-prefix {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 5px 4px 0px 0px;
  width: 28px;
  height: 44px;
  flex: none;
  order: 0;
  align-self: stretch;
}
.text-incomplete{
  color: #13c2c2;
}

.status-symbol-incomplete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 74px;
  height: 22px;
  background: #e6fffb;
  border: 1px solid #87e8de;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #13c2c2;
}

.status-symbol-pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 76px;
  height: 22px;
  background: #e6fffb;
  border: 1px solid #87e8de;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #13c2c2;
}

.text-pending{
  color: red
}

.status-symbol-approved {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 67px;
  height: 22px;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #52c41a;
}

.text-approved{
  color: #52c41a;
}

.status-symbol-live {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 38px;
  height: 22px;
  background: #e6fff3;
  border: 1px solid #87e8de;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #0e6239;
}

.text-live{
  color: #0e6239;
}

.status-symbol-expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 56px;
  height: 22px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #faad14;
}

.text-expired{
  color: #faad14;
}

.status-symbol-rejected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 66px;
  height: 22px;
  background: #ffe6f5;
  border: 1px solid #cc73b9;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a41e56;
}

.text-rejected{
  color: #a41e56;
}

.status-symbol-lv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 72px;
  height: 22px;
  background: #e4e9d4;
  border: 1px solid #a73a49;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a73a49;
}

.status-symbol-pv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 92px;
  height: 22px;
  background: #e4e9d4;
  border: 1px solid #a73a49;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a73a49;
}

.status-symbol-av {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 99px;
  height: 22px;
  background: #e4e9d4;
  border: 1px solid #a73a49;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a73a49;
}

.status-symbol-iv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;
  width: 109px;
  height: 22px;
  background: #e4e9d4;
  border: 1px solid #a73a49;
  border-radius: 2px;
  flex: none;
  order: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a73a49;
}



.text-lv{
  color: #a73a49;
}

.symbol {
  width: 24px;
  height: 24px;
  color: red;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-incomplete {
  color: #08979c;
  width: 24px;
  height: 24px;
  /* color: red; */
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-rejected {
  color:#a41e56;
  width: 24px;
  height: 24px;
  /* color: red; */
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-exl {
  color: #d46b08;
  width: 24px;
  height: 24px;
  /* color: red; */
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-cloud {
  width: 24px;
  height: 24px;
  color: #0f2a6f;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-approved {
  width: 24px;
  height: 24px;
  color: #389e0d;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-lv {
  width: 24px;
  height: 24px;
  color: #a49f1e;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.symbol-expired {
  width: 24px;
  height: 24px;
  color: #d4b106;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
}

.static-value-digit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2.5px 0px;
  width: 41px;
  height: 37px;
  flex: none;
  order: 1;
}

.static-value-digit-text {
  width: 41px;
  height: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

.table-cardbody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  /* width: 1232px; */
  /* height: 100; */
  flex: none;
  order: 1;
  align-self: stretch;
}

.tablebody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  height: inherit;
  /* width: 1184px; */
  /* height: 648px; */
  background: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
}

.table-main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  /* height: 600px; */
  width: 100%;
  border-radius: 2px 2px 0px 0px;
  flex: none;
  order: 0;
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 16px;
  gap: 10px;
  width: 100%;
  /* width: 1140px; */
  height: 48px;
  background: rgba(255, 255, 255, 1e-5);
  flex: none;
  order: 0;
  align-self: stretch;
}

.btnGrpWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 256px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.filterGrpWrapper1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 32px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.filterGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 188px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.settingGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 72px;
  height: 16px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.btnStyle {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
  color: white;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btnStyle1 {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.btnStyle1:hover {
  color: 40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.btnStyle:hover {
  color: white;
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
}

.table-main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  /* width: 1140px; */
  height: 600px;
  border-radius: 2px 2px 0px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-real {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  /* width: 1778px; */
  height: 504px;
  flex: none;
  order: 1;
}

.widthTable {
  width: 1440px;
}

.assign {
  color: #389e0d;
}

.assign > ant-table-wrapper {
  width: 1240px;
}

.timeline-wrapper-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}