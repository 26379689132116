.overlay-div {
    position: fixed;
    display: flex;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1000;
    overflow: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingScreenTipDiv {
   position: relative;
   bottom: 40px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}

.loadingScreenTipDiv .ant-spin-dot-item {
    background: rgba(15, 42, 111, 1);
}

.loadingScreenTipDiv .ant-spin-text {
    color: rgba(15, 42, 111, 1);
    font-size: 18px;
    margin-top: 5px;
}