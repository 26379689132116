.report-create {
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.report-create .teams-wrapper {
  width: 100%;
  margin: 0;
}

.report-create .team-item {
  border: none;
  outline: none;
  min-width: calc(100% / 3);

  &:disabled {
    cursor: not-allowed;
  }
}

.create-wrapper {
  height: calc(100vh - 52px - 102px);
}

.report-field-item {
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);


}

.report-field {
  width: 100%;
}

.report-question-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.teamName {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  border-bottom: 1px solid #b5aaaa;
}

.step1 .fields {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.fields-search {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.report-footer {
  background-color: white;
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.queryBuilder .ant-input {
  width: auto;
}

.report-query-builder {
  padding: 24px;
  border: none;
}

.report-query-builder .ruleGroup {
  background: rgba(249, 249, 249, 1);
}

.report-query-builder .ant-btn-primary {
  background-color: #0f2a6f;
}

.rule-fields .ant-select {
  min-width: 150px;
}

.rule-operators .ant-select {
  min-width: 100px;
}

.step3-fields {
  background-color: rgba(249, 249, 249, 1);
  padding: 12px 16px;
  height: calc(100% - 112px - 118px);
  overflow: auto;
}

.fields-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .field-item {
    width: min-content;
  }
}

.step3 .field {
  flex-direction: column;
  margin-bottom: 24px;
}

.step3_input {
  justify-content: space-between;
  padding: 18px 16px;
}

.step3_input .switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.step3_input .input-frame {
  width: 30%;
}

.step3-select-all {
  display: flex;
  justify-content: space-between;
}

.step3 .field-name {
  color: rgba(0, 0, 0, 0.45);
}

.add-more-modal {
  width: 570px !important;
}

.add-more-modal .ant-modal-body {
  padding-top: 0px;
}

.add-more-modal .fields-search {
  border: none;
  padding: 24px 0;
  width: 100%;
}

.add-more-modal .ant-input-affix-wrapper {
  width: 100%;
}

.add-more-modal .userBtn {
  width: 100%;
}

.add-more-modal .fields {
  height: 300px;
  overflow: auto;
}

.status-field {
  padding: 30px 16px;
}

.create-report-wrapper {
  height: calc(100% - 48px);
}