.status-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 10px;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.status-check {
  flex: none;
  order: 0;
  flex-grow: 0;
}

.status-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 457px;
  height: 201px;
  left: 88px;
  top: 310px;
}

.check-circle {
  font-size: 56px;
  margin-bottom: -59px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #52C41A;
  border-radius: 50%;
}

.vector{    
background: #52C41A;
}

.status-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 457px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.description-title{
height: 43px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: rgba(0, 0, 0, 0.85);
flex: none;
order: 0;
flex-grow: 0;
}


.description-subtitle{
width: 226px;
height: 22px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
color: rgba(0, 0, 0, 0.45);
flex: none;
order: 1;
flex-grow: 0;
}

.status-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 68px;
  height: 32px;
  flex: none;
  order: 2;
  flex-grow: 0;
}
