.shelf-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 8px;
    height: 88px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.shelfs-right-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 375px;
    height: 40px;
}

.shelfs-left-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    height: 40px;
}

.searchShelfBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 350px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.importCsvBtn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addShelfBtn {
    width: 100%;
    height: 100%;
    background: #0F2A6F;
    border: 1px solid #0F2A6F;
}

.addShelfBtn:hover,
.addShelfBtn:visited,
.addShelfBtn:focus {
    background: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
}

.shelf-table-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: #FFFFFF;
    flex: none;
    margin-top: 16px;
  
}

.shelfs-table-topbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px 16px;
    gap: 10px;
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 1e-05);
   
}

.shelfRightFilterGrpWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: fit-content;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shelfRightFilterGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 188px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shelfRightSettingGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 72px;
    height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
}
.statusStyleText{
width: 41px;
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
}

.activeGreen{
width: 34px;
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #52C41A;
    flex: none;
    order: 0;
    flex-grow: 0;
    vertical-align: bottom;
    margin-left: 5px;
}

.searchShelfBtn .ant-input-group-addon>button,
.searchShelfBtn .ant-input-group-addon>button:hover,
.searchShelfBtn .ant-input-group-addon>button:focus,
.searchShelfBtn .ant-input-group-addon>button:visited {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
}

.shelfTipDiv {
    margin: 20px 0;
    padding: 30px 50px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}

.shelfTipDiv .ant-spin-dot-item {
    background: rgb(15, 42, 111);
}

.shelfTipDiv .ant-spin-text {
    color: rgb(15, 42, 111);
    font-size: 16px;
    margin-top: 5px;
}

.m-5{
    margin: 5px;
}
@media only screen and (max-width: 850px) {
    .shelf-card{
        flex-direction: column;
        height: auto;
    }

        .shelfs-right-content {
            width: fit-content;
        }
    
        .shelfs-right-content .ant-input-group-wrapper {
            width: auto;
        }
    
        .shelfs-right-content .ant-input-wrapper {
            width: auto;
        }
    
        .shelfs-right-content .ant-input-affix-wrapper {
            width: auto;
        }
    
        .shelfs-right-content .ant-input-affix-wrapper>input.ant-input {
            width: auto;
        }
}

@media only screen and (max-width: 600px) {
    .shelfs-left-content {
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .shelfs-left-content .importCsvBtn{
        width: fit-content;
    }

    .shelfs-left-content .addShelfBtn{
        width: fit-content;
    }
}

@media only screen and (max-width: 500px) {

    .shelfs-right-content .ant-input-affix-wrapper>input.ant-input {
        width: 120px;
    }
}

@media only screen and (max-width: 550px) {
    .shelfs-table-topbar {
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
    }
}