.wrapperXmlQuestions{
margin: 0px 10px 0px 3px;
}
.xmlEditor{
    display: flex;
    justify-content: flex-start;
}
.editor{
    width: 70%;
}
.dropdownPromo{
    width: 30%;
}
