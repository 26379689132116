.create-style {
  float: right;
  margin-right: 20px;
}

.create-style .ant-drawer-footer {
  margin-left: 250px;
}

.create-style-edit {
  margin-left: 500px;
}

.create-style-edit .ant-drawer-footer {
  margin-left: 350px;
}

.create-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  height: 992px;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.create-frame {
  width: 520px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.create-promo-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 54px;
  position: absolute;
  width: 500px;
  height: 2320.01px;
  left: 0px;
  top: 0px;
}

.page-header-review{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 24px;
height: 200px;
overflow-y: auto;
gap: 20px;
background: #E6F7FF;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.page-header-review-reject{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  height: 95px; 
  background: #E6F7FF;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.textarea-review{
  margin-top: 40px;
}

.content-review{
  display: flex;
flex-direction: row;
align-items: center;
padding: 8px 0px 0px;
height: 63px;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.layout-block-review{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 32px;
width: 372px;
height: 55px;
flex: none;
order: 0;
flex-grow: 0;
}

.frame-review{
  display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
padding: 0px;
height: 55px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 1;
}

.lay-static-review{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;
display: none;
width: 124px;
height: 63px;
flex: none;
order: 0;
flex-grow: 0;
}

.static-titlelay{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 4px;
width: 146px;
height: 22px;
flex: none;
order: 0;
flex-grow: 0
}

.static-valuelay{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
flex: none;
order: 1;
flex-grow: 0;
}

.text-kr{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
display: flex;
align-items: center;
color: rgba(0, 0, 0, 0.45);
flex: none;
order: 0;
flex-grow: 0;
}


.text-value-review{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: rgba(0, 0, 0, 0.85);
flex: none;
order: 0;
flex-grow: 0;
}

.button-review{
  box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 5px 6px;
width: 13px;
height: 32px;
background: #FFFFFF;
margin-right: 79px;
border: 1px solid #D9D9D9;
box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
border-radius: 2px;
flex: none;
order: 0;
flex-grow: 0;
}


.button-group{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
width: 162px;
height: 32px;
flex: none;
order: 1;
flex-grow: 0;
}

.dp-review{
  display: flex;
flex-direction: row;
align-items: center;
padding: 5px 16px;
gap: 8px;
width: 98px;
height: 32px;
background: #0F2A6F;
border-radius: 2px;
flex: none;
order: 1;
flex-grow: 0;
}

.tag-review{
  box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
padding: 1px 8px;
gap: 9px;
display: none;
width: 96px;
height: 22px;
background: #E6F7FF;
border: 1px solid #91D5FF;
border-radius: 2px;
flex: none;
order: 0;
flex-grow: 0;
}

.timeline-wrapper-success {
  padding: 16px;
  border-radius: 6px;
  border: 0.5px solid #27AE60;
  background: #F1FFF7;
  color:  #27AE60;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .date-label {
    color: #979797;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.timeline-content{
  display: flex;
  justify-content: space-between;

}
.timeline-wrapper {
  padding: 16px;
  border-radius: 6px;
  background: #F6F6F6;
  color:  #828282;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .date-label {
    color: #979797;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}