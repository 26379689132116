.template_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    min-height: 800px;
}

.template_header {
    height: 28px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-bottom: 70px;
}

.button_div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.mb-25 {
    margin-bottom: 25px;
}

.mt-5 {
    margin-top: 5px;
}

.full-width {
    width: 100%;
}

.upload-template {
    min-height: 100px;
    width: 100%;
    border: 1px solid #d9d9d9
}

.fileIcon {
    font-size: 30px;
    margin-right: 10px;
}

.deleteBtn {
    border: none;
    color: black !important;
}

.header_template {
    font-weight: 500;
    padding: 10px;
    width: 50%;
    background-color: rgb(190 193 196);
    border-color: rgb(190 193 196);
}

.right-border {
    border-right: 1px solid #8f8b8b;
}

.arrow-icon {
    margin-right: 5px;
    font-size: 20px;
}