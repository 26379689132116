.formBuilderBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 10px;
    isolation: isolate;
    width: 100%;
    background: #FFFFFF;
}

.formBuilderFrameWrapper{
    width: 604px;
}

.formBuilderContentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 604px;
}

.formBuilderFrameOne{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 8px;
    width: 604px;
    height: 49px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.formBuilderFrameOneLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    isolation: isolate;
    /* width: 156px; */
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.formBuilderFrameOneViewBtn{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 16px;
    gap: 4px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #0F2A6F;
    border-radius: 0px 2px 2px 0px;
    cursor: pointer;
}

.formBuilderFrameOneLeft a{
    color: black;
}

.fomrBuilderFrameOneEditBtn{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 16px;
    gap: 4px;
    height: 32px;
    background: #0F2A6F;
    border-radius: 2px 0px 0px 2px;
    color: white;
    cursor: pointer;
}

.formBuilderFrameOnePublishBtn{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 10px;
    width: 79px;
    height: 32px;
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.formBuilderFrameOnePublishBtnEnable{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 10px;
    width: 79px;
    height: 32px;
    background: #52C41A;
    border: 1px solid #52C41A;
    border-radius: 2px;
    color: white;
    cursor: pointer;
}

.cna{
    cursor: not-allowed;
}

.formBuilderFrameTwo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 24px;

    width: 604px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoActiveDeck{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 16px;

    width: 604px;
    background: #FAFAFA;
    border-left: 2px solid #40A9FF;
}

.formBuilderFrameTwoPromoForm{
display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 588px;
    height: 70px;
}

.formBuilderFrameTwoPromoFormFrame{
display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 588px;
    height: 70px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.formBuilderFrameTwoPromoQuestion{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 380px;
    height: 70px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoPromoLabel{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 4px;
    gap: 4px;
    width: 380px;
    height: 30px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: rgba(0, 0, 0, 0.85);
}

.formBuilderFrameTwoPromoQuestionInput{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 380px;
    height: 32px;

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
}

.formBuilderFrameTwoPromoType{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 200px;
    height: 70px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.formBuilderFrameTwoPromoLabelType{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 4px;
    gap: 4px;

    width: 200px;
    height: 30px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: rgba(0, 0, 0, 0.85);
}

.formBuilderFrameTwoPromoTypeSelect{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 200px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoPromoTypeSelectBtn{
    width: inherit;
}


.formBuilderFrameTwoDynamic{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 588px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicFormFrameWrapper{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 588px;

    flex: none;
    order: 0;
    flex-grow: 1;
}

.formBuilderFrameTwoDynamicOptionFrame{
display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 588px;
    height: 40px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicOptionValueFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 588px;
    height: 40px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicOptionValueInputWrapper{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 588px;
    height: 40px;

    background: #FFFFFF;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicOptionValueInputWrapper .ant-input-affix-wrapper,
.formBuilderFrameTwoDynamicOptionValueInputWrapper .ant-input{
    width: 100% !important;
}

.cp{
    cursor: pointer;
}

.formBuilderFrameTwoDynamicSubOptionFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 8px;

    width: 588px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.formBuilderFrameTwoDynamicSubOptionValueFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px 8px;
    gap: 4px;

    width: 580px;
    border-left: 1px solid #BAE7FF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicSubOptionValueGroup{
width: 572px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 8px;

    width: 572px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin-top: 5px;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupSubFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px 8px;
    gap: 4px;

    width: 564px;
    border-left: 1px solid #FFC069;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupSubFrame .ant-input-affix-wrapper,
.formBuilderFrameTwoDynamicSubOptionValueGroupSubFrame .ant-input {
    width: 100% !important;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupSubOtherFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px 8px;
    gap: 4px;

    width: 548px;
    border-left: 1px solid #B37FEB;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    border-radius: 2px;
}

.formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper .ant-input-affix-wrapper,
.formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper .ant-input {
    width: 100% !important;
}

.formBuilderFrameTwoDynamicSubOptionValueAddFrame{
display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 184px;
    padding: 4px 0px 0px 8px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.formBuilderFrameTwoDynamicSubOptionValueAddFrameOtherBtn{
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1px 8px;
        gap: 8px;
    
        width: 97px;
        height: 22px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #0F2A6F;
}

.fomBuilderSelectStyle .ant-select .ant-select-selector{
    height: 22px;
}

.fomBuilderSelectStyle .ant-select .ant-select-selector .ant-select-selection-placeholder,
.fomBuilderSelectStyle .ant-select .ant-select-selector .ant-select-selection-item{
    line-height: 20px;
}

.formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn:hover,
.formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn:visited,
.formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn:focus{
    color: #0F2A6F;
    border-color: #0F2A6F;
}

.formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn{
display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 8px;

    width: 97px;
    height: 22px;
    background: #FFFFFF;

    border: 1px solid #D9D9D9;

    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0F2A6F;
}

.formBuilderActionsDiv{
display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;

    width: 588px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    flex: none;
        order: 5;
        align-self: stretch;
        flex-grow: 0;
}

.formBuilderActionsWrapper{
display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;

    width: 254px;
}

.copyDiv{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;

    width: 40px;
    background: #FFFFFF;

    box-shadow: inset -1px 0px 0px #F0F0F0;
    cursor: pointer;
}

.delDiv{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;

    width: 40px;
    cursor: pointer;

    background: #FFFFFF;
}

.formBuilderFrameThree{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px 0px;
    gap: 8px;

    width: 604px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameThreeDividerWrapper{
display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 604px;
}

.formBuilderFrameThreeDividerOne{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 213.5px;
    height: 0px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.formBuilderFrameThreeLineOne{
width: 213.5px;
    height: 0px;
    border: 1px solid #D9D9D9;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameThreeDividerTwo{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 213.5px;
    height: 0px;
    flex: none;
    order: 2;
    flex-grow: 1;
}

.formBuilderFrameThreeLineTwo{
width: 213.5px;
    height: 0px;
    border: 1px solid #D9D9D9;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formBuilderFrameThreeDividerBtn{
display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 8px;

    width: 145px;
    height: 26px;

    background: #FFFFFF;

    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
        color: rgba(0, 0, 0, 0.85);
}

.formBuilderFrameTwoDynamicAddOptionsFrame{
display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 184px;

    flex: none;
    order: 4;
    flex-grow: 0;
}

.saveBtn{
    height: 26px;
    padding: 1px 8px;
}

.saveQuestionFrame{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px 0px;
    gap: 4px;

    width: 604px;
}

.savedQuesOptionDiv{
    width: 100%;
}

.savedQuesOptionDiv .ant-select{
    width: 100%;
}

.saveQuestionContent{
    width: 100%;
}

.saveQuestionLabel{
    width: 100%;
    display: block;
    margin-bottom: 5px;
}

.formBuilderViewWrapper{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 16px;

    width: 604px;
}

.formBuilderViewContent{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px 0px;
    gap: 4px;

    width: 604px;
}

.formBuilderPublishContent{
    border-bottom: 1px solid lightgrey;
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px 0px;
    gap: 4px;

    width: 604px;
}

.formBuilderViewLabelWrapper{
    display: flex;
    justify-content: space-between;
}

.formBuilderViewEditBtn{
    margin-right: 10px;
    cursor: pointer;
}

.formBuilderViewBtnGrp{
    display: flex;
}

.copyDiv .formBuilderCopyBtn,
.delDiv .formBuilderCopyBtn,
.delDiv .formBuilderCopyBtn:hover,
.delDiv .formBuilderCopyBtn:hover{
    border: none;
    outline: none;
    background: transparent;
}

.m-btm-20{
    margin-bottom: 20px;
}

.requiredCheckboxDiv{
    display: flex;
    align-items: center;
}

.requiredSpanCheck{
    font-size: 12px;
}

.formBuilderFrameOneLeft .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: #0F2A6F;
}