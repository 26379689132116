.promo-type-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 8px;
  height: 88px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f0f0f0;
}

.teamNameAndTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.promo-right-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 375px;
  height: 40px;
}

.promo-left-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: 40px;
}

.searchPromoTypeBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 350px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.addPromoTypeBtn {
  width: 100%;
  height: 100%;
  background: #0f2a6f;
  border: 1px solid #0f2a6f;
}

.addPromoTypeBtn:hover,
.addPromoTypeBtn:visited,
.addPromoTypeBtn:focus {
  background: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.searchPromoTypeBtn .ant-input-group-addon > button,
.searchPromoTypeBtn .ant-input-group-addon > button:hover,
.searchPromoTypeBtn .ant-input-group-addon > button:focus,
.searchPromoTypeBtn .ant-input-group-addon > button:visited {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.promo-table-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  flex: none;
  margin-top: 16px;
}

.promo-table-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: rgba(255, 255, 255, 1e-5);
}

.promoRightFilterGrpWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: fit-content;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.promoRightFilterGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 188px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.promoRightSettingGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 72px;
  height: 16px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.statusStyleText {
  width: 41px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.activeGreen {
  width: 34px;
  height: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #52c41a;
  flex: none;
  order: 0;
  flex-grow: 0;
  vertical-align: bottom;
  margin-left: 5px;
}

.promoTypePopWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  left: -30px;
  top: 5px;
  width: 278px;
  height: 260px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.promoTypePopHead {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;
  width: 278px;
  height: 40px;
  background: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.promoTypePopHeadTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;
  width: 246px;
  height: 40px;
}

.promoTypePopFilterText {
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.promoTypePopBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 278px;
  height: 164px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f0f0f0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.promoTypePopSearchBtn .ant-input-group-addon > button,
.promoTypePopSearchBtn .ant-input-group-addon > button:hover,
.promoTypePopSearchBtn .ant-input-group-addon > button:focus,
.promoTypePopSearchBtn .ant-input-group-addon > button:visited {
  background-color: transparent;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.45);
}

.promoTypeDatePickerDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 246px;
  height: 52px;
  flex: none;
  flex-grow: 0;
}

.promoTypeDatePickerText {
  height: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.promoTypePopActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: 278px;
  height: 56px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f0f0f0;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.promoTypePopCancelBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  width: 115px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 1;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.promoTypePopApplyBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  width: 115px;
  height: 32px;
  background: #0f2a6f;
  box-shadow: inset -1px 0px 0px #0f2a6f;
  border-radius: 2px;
  border-color: #0f2a6f;
  flex: none;
  order: 1;
  flex-grow: 1;
  color: #ffffff;
  cursor: pointer;
}

.promoTypePopSelect {
  width: 100%;
}

.mr-10 {
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  .promo-type-card{
    flex-direction: column;
    height: auto;
  }
}
@media only screen and (max-width: 700px) {
.promo-right-content {
    width: fit-content;
  }

  .promo-right-content .ant-input-group-wrapper {
    width: auto;
  }

  .promo-right-content .ant-input-wrapper {
    width: auto;
  }

  .promo-right-content .ant-input-affix-wrapper {
    width: auto;
  }

  .promo-right-content .ant-input-affix-wrapper>input.ant-input {
    width: auto;
  }
}

@media only screen and (max-width: 600px) {
.promo-left-content{
  flex-direction: column;
  height: auto;
}
}

@media only screen and (max-width: 400px) {
.promo-right-content .ant-input-affix-wrapper>input.ant-input {
    width: 120px;
  }
}

@media only screen and (max-width: 550px) {
  .promo-table-topbar {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
  }
}