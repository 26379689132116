.site-layout .site-layout-background {
        background: #fff;
        height: 102px;
}

.page-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 24px;
        gap: 8px;
        height: 102px;
        background: #FFFFFF;
}

.heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 10px;
        height: 40px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
}

.breadcrumb {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        width: fit-content;
        /* width: 1184px; */
        height: 22px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
}

.components-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        height: 40px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
}

.heading-extra {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        justify-content: space-between;
        /* width: fit-content; */
        height: 40px;
        flex: none;
        order: 1;
        flex-grow: 0;
}

.page-text {
        height: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        flex: none;
        order: 0;
        flex-grow: 0;
}

.user-title {
        height: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;
}

.bellIconStyle {
        color: rgba(0, 25, 82, 1);
        font-size: 18px;
        cursor: pointer;
}

.notifySpan{
        font-size: 12px;
        color: #fff;
        background-color: rgba(255, 77, 79, 1);
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 20px;
        position: absolute;
        top: -12px;
        left: 12px;
        border-radius: 50%;       
        cursor: pointer;
}

.bellAvatar {
        background: transparent;
        cursor: pointer;
}

.downIcon {
        margin-right: 10px;
        margin-left: 5px ;
        cursor: pointer;
}

.userAvatar {
        background-color: rgba(0, 25, 82, 1);
        cursor: pointer;
}

.header-p {
        padding: 0px;
        height: 102px;
}

.ant-popover-inner {
        background-color: transparent;
        box-shadow: none;
}

.topbarDropdownWrapper {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        margin: -12px -16px;
        width: 360px;
        min-width: 160px;
        flex-shrink: 0;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        top: 0px;
        right: 50px;
        position: absolute;
}

.isoUserDropdown {
        padding: 7px 0;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: fit-content;
        min-width: 160px;
        flex-shrink: 0;
}

.isoDropdownLink {
        font-size: 14px;
        line-height: 1.1;
        padding: 7px 15px;
        background-color: transparent;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        border: none;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
}

.userAvatar{
    background-color: rgba(15, 42, 111, 1);
}
    
.isoDropdownLink:hover {
        background-color: #f5f6f8;
}

.profileClr{
        color: rgba(255, 77, 79, 1);
}

.backBtnStyle,
.backBtnStyle:hover{
 color: black;
}
.arrowBtnStyle{
font-size: 16px;
}

.notificationWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        position: relative;
        width: 320px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        position: absolute;
        top: 0px;
        right: -30px;
}

.notificationHead {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 320px;
        height: 54px;
        background: #FFFFFF;
}

.notificationBody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 320px;
        background: #FFFFFF;
        overflow-y: auto;
        overflow-x: hidden;
}

.bodyFrame {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 0px 4px;
        gap: 180px;
        height: fit-content;
        background: #FFFFFF;
        border-bottom: 1px solid #F5F5F5;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
}

.textDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

}

.textDivFrame {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
}

.titleBodyText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
}

.NotificationDateText {
        width: 135px;
        height: 20px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.45);
        flex: none;
        order: 1;
        flex-grow: 0;
        text-align: left;
}

.notificationHeadTitleWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 10px;
        width: 320px;
        height: 54px;
        background: #FFFFFF;
        box-shadow: inset 0px -1px 0px #F0F0F0;
}

.titleLeftText {
        width: 81px;
        height: 22px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.85);
}

.titleRightText {
        height: 22px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #F5222D;
        flex: none;
        order: 0;
        flex-grow: 0;
}

.notificationHeadTitleWrapper1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 10px;
        width: 320px;
        height: 38px;
}

.viewAllText,
.viewAllText:hover {
        height: 22px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #0F2A6F;
}

.isoIconWrapper{
        position: relative;
        line-height: normal;
}        
.noNotificationDiv{
display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ff4d4f;
        height: 100%;
}

@media only screen and (max-width: 768px) {
        .heading-extra{
                width: fit-content;
                flex-direction: column;
        }
}

@media only screen and (max-width: 600px) {
        .heading-extra .downIcon,
        .heading-extra .user-title{
                display: none;
        }

        .heading-extra .userAvatar{
                margin-bottom: 10px;
        }
}