.start-node-label {
  font-size: 12px;
  font-weight: 500;
  
}
.start-node {
  display: flex;
  align-items: center;
  color: #0f2a6f;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  background-color: #f7f9ff;
  border: 1px solid #0f2a6f;
  justify-content: center;
}
.stage-node-label{
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.promo-node.next-highlight{
  border-color:#0f2a6f;
  color: #0f2a6f;
}
.stage-node-wrapper{
  width: 100%;
}
.promo-node {
  border: 1px solid #d9d9d9;
  color: #000000;
  background-color: #fafafa;
  padding: 0 8px;
  display: flex;
  align-items: center;
  width:70px;
  
}
