.notificationCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 8px;
}

.notificationFrame {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 851px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 851px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
}

.cardHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 851px;
    height: 56px;
    background: #FFFFFF;
}

.headTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 851px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
}

.headTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    height: 24px;
}

.headTitleText {
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
}

.headExtra {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 8px;

    width: 120px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
}

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 851px;
    background: #FFFFFF;
}

.listWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 803px;
}

.singlelistWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 803px;
}

.singleListFrame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 0px 8px;
    gap: 180px;
    width: 803px;
    background: #FFFFFF;
    border-bottom: 1px solid #F5F5F5;
}

.leftDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex-wrap: wrap;
}

.notificationTitleText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0F2A6F;
}
.notificationReadTitleText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
}

.subText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.45);

    flex: none;
    order: 1;
    flex-grow: 0;
}

.rightDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.dateText {
    width: 135px;
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.45);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.buttonsFrame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin-bottom: 10px;
    width: 851px;
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.clearBtnStyle,
.clearBtnStyle:hover,
.clearBtnStyle:focus,
.clearBtnStyle:visited {
    border-color: rgba(255, 77, 79, 1);
    color: rgba(255, 77, 79, 1);
}

.notificationCheckBtn{
    background: transparent;
    border: none;
    outline: none;
}

.w-44{
    width: 44px;
}