.workflow-create-wrapper {
  padding: 24px;
}
.create-workflow-label {
  font-size: 12px;
}
.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 270px);
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  background-color: white;
  padding: 12px;
}
.workflow-create {
  display: flex;
  align-items: center;
}
.workflow-create .error-label {
  position: absolute;
}
.info-label {
  color: grey;
}
.list-team-item {
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  padding: 12px 24px;
  cursor: pointer;
}
.form {
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  background-color: white;
  /* padding: 24px; */
  margin-bottom: 16px;
}
.workflow-create-button {
  margin-top: 24px;
}
.ant-input {
  margin-top: 4px;
}
.workflow-type {
  width: 140px;
  flex: auto;
}
.workflow-type .ant-select-selector {
  margin-top: 4px;
}
.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 16px;
  font-size: 12px;
  overflow: auto;
}
.teams-text {
  color: #777777;
  margin-bottom: 24px;
  font-weight: 500;
}
.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  padding: 8px;
  border: 1px solid #dadada;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: grab;
  background-color: white;
}
.dndflow .dndnode.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 185px;
    background-color: #f4f4f4;
  }
}
.react-flow__attribution{
  display: none;
}