.add-user-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 8px;
    height: 88px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

@media only screen and (max-width: 840px) and (min-width: 550px) {
    .tabs-wrapper {
      /* overflow-x: auto; */
      flex-direction: column;
      height: 500px;
      width: auto;
      justify-content: center;
    }    
  }
  
  @media only screen and (max-width: 500px) {
   
    .tabs-wrapper  {
      flex-direction: column;
      height: auto;
      width: auto;
      justify-content: center;
    }
  }

.right-content{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 24px;
width: 375px;
height: 40px;
flex: none;
order: 0;
flex-grow: 0;
}

.userErrorBtn {
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
width: 350px;
height: 40px;
background: #FFFFFF;
border-radius: 2px;
flex: none;
order: 0;
flex-grow: 0;
}

.search-button{
    background-color: white;
border-color: rgb(168, 169, 172) ;
}

.shelfBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shelfBtn .ant-input-group-addon > button,
.shelfBtn .ant-input-group-addon > button:hover,
.shelfBtn .ant-input-group-addon > button:focus,
.shelfBtn .ant-input-group-addon > button:visited{
    background-color: white;
border-color: rgb(168, 169, 172) ;
}

.left-content{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 24px;
height: 40px;
flex: none;
order: 1;
flex-grow: 0;
width: fit-content;
}

.addUserBtn{
background-color: rgba(15, 42, 111, 1) ;
border-color: rgba(15, 42, 111, 1) ;
}

.addUserBtn:hover,.addUserBtn:visited,.addUserBtn:focus{
background-color: rgba(15, 42, 111, 1) ;
border-color: rgba(15, 42, 111, 1) ;
}

.user-table-card{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;
margin-top: 16px;
background: #FFFFFF;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.table-topbar{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 8px 16px;
gap: 10px;
height: 48px;
background: rgba(255, 255, 255, 1e-05);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.btnGrpWrapper{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
width: 256px;
height: 32px;
flex: none;
order: 0;
flex-grow: 0;
}

.shelf-right-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 375px;
    height: 40px;
}

.shelf-left-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 286px;
    height: 40px;
}

.filterGrpWrapper{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;
width: 276px;
height: 32px;
flex: none;
order: 1;
flex-grow: 0;
}

.filterGrp{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
width: 188px;
height: 32px;
flex: none;
order: 0;
flex-grow: 0;
}

.settingGrp{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
margin-left: 12px;
gap: 12px;
width: 72px;
height: 16px;
flex: none;
order: 2;
flex-grow: 0;
}

.title-box{
    box-sizing: border-box;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 16px;
gap: 8px;
width: 95px;
height: 32px;
background: #FFFFFF;
border: 1px solid #D9D9D9;
box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
border-radius: 2px;
}

.icon-wrapper{
    width: 14px;
height: 25px;
}

.union{
    position: absolute;
width: 12.22px;
height: 12px;
left: 0.56px;
top: 1px;
background: rgba(0, 0, 0, 0.85);
}

.export{
    position: absolute;
width: 12.22px;
height: 12px;
left: 0.56px;
top: 1px;
background: rgba(0, 0, 0, 0.85);
}

.vector{
    position: absolute;
left: 7.14%;
right: 5.59%;
top: 7.13%;
bottom: 7.16%;
background: rgba(0, 0, 0, 0.25);
}

.export-text{
    width: 41px;
height: 22px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
text-align: center;
color: rgba(0, 0, 0, 0.85);
}

.spanReq{
width: 7px;
height: 22px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
text-align: right;
color: #FF4D4F;
flex: none;
order: 0;
flex-grow: 0;
}

.mt-5{
margin-top: 5px;
}

.passwordSpan{
height: 22px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
display: flex;
align-items: center;
color: rgba(0, 0, 0, 0.85);
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 13px;
}

.ant-modal-body form .ant-radio-checked .ant-radio-inner{
border-color: rgba(15, 42, 111, 1);
}
.ant-modal-body form .ant-radio-inner::after {
background: rgba(15, 42, 111, 1);
}

.ant-modal-confirm-btns .ant-btn-primary{
background: rgba(15, 42, 111, 1);
border-color: rgba(15, 42, 111, 1);
}

.errorLabel{
color: #FF4D4F;
font-size: 12px;
font-weight: bold;
}

.btnStyle {
line-height: 1.5715;
position: relative;
display: inline-block;
font-weight: 400;
white-space: nowrap;
text-align: center;
background-image: none;
border: 1px solid transparent;
box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
cursor: pointer;
transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
touch-action: manipulation;
height: 32px;
padding: 4px 15px;
font-size: 14px;
border-radius: 2px;
color: rgba(0, 0, 0, 0.85);
border-color: rgb(15, 42, 111) !important;
background: rgb(15, 42, 111) !important;
color: white !important;
text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btnStyle1 {
line-height: 1.5715;
position: relative;
display: inline-block;
font-weight: 400;
white-space: nowrap;
text-align: center;
background-image: none;
border: 1px solid transparent;
box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
cursor: pointer;
transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
touch-action: manipulation;
height: 32px;
padding: 4px 15px;
font-size: 14px;
border-radius: 2px;
color: rgba(0, 0, 0, 0.85);
border-color: #d9d9d9;
background: #fff;
}

.btnStyle1:hover {
color: 40a9ff;
border-color: #40a9ff;
background: #fff;
}

.btnStyle:hover {
color: white;
border-color: rgb(15, 42, 111);
background: rgb(15, 42, 111);
}

.validationList{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;
height: 68px;
flex: none;
order: 1;
flex-grow: 0;
}

.listCol1{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;
height: 68px;
flex: none;
order: 0;
flex-grow: 0;
margin-right: 25px;
}

.listCol2{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;
height: 44px;
flex: none;
order: 1;
flex-grow: 0;
}

.mb-4{
margin-bottom: 4px;
}

.mb-8{
margin-bottom: 8px;
}

.mb-25{
margin-bottom: 25px;
}

.label-grey{
color: rgba(0, 0, 0, 0.45);
}

.w-100{
width: 100% !important;
}

.checkGreen{
color: #52C41A !important;
}

.passGreen{
color: #52C41A;
}

.failGrey{
color: rgba(0, 0, 0, 0.45);
}

.footerBtnNavy{
background-color: rgba(15, 42, 111, 1);
border-color: rgba(15, 42, 111, 1);
color: white;
}
.footerBtnNavy,
.footerBtnNavy:hover,
.footerBtnNavy:focus,
.footerBtnNavy:active
{
background-color: rgba(15, 42, 111, 1);
border-color: rgba(15, 42, 111, 1);
color: white;
}
.footerBtnNavy[disabled],
.footerBtnNavy[disabled]:hover,
.footerBtnNavy[disabled]:focus,
.footerBtnNavy[disabled]:active
{
background-color: rgba(15, 42, 111, 1);
border-color: rgba(15, 42, 111, 1);
color: white;
}

.alertStyle{
display: flex;
flex-direction: row;
padding: 9px 16px;
gap: 10px;
align-items: center;
position: absolute;
width: 395px;
min-height: 40px;
left: calc(50% - 395px/2 + 0.5px);
top: 40px;
height: auto;
}

.ant-switch-checked.switchStyle{
background: rgba(15, 42, 111, 1);
}

.mt-16{
    margin-top: 16px;
}

.brand-card{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px 24px;
gap: 8px;
margin-top: 24px;
width: 100%;
flex: none;
order: 1;
align-self: stretch;
}

.page-header1{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 24px 0px;
width: 100%;
height: 108px;
background: #FFFFFF;
}

.layout-block{
    display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
width: 100%;
height: 36px;
}

.heading-left{
    display: flex;
flex-direction: row;
align-items: center;
padding: 6px 0px;
gap: 16px;
width: 268px;

}

.heading-left-wrapper{
    display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 12px;

}

.head-text{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: rgba(0, 0, 0, 0.85);
}

.layout-content{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 100%;
height: 40px;
}

.content-text{
width: 100%;
height: 40px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #595959;
flex: none;
order: 0;
align-self: stretch;
}

.width-scrubber{
    display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 400px;
width: 400px;
height: 0px;
}

.tabs-top{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 16px 0px 0px;
width: 100%;
height: 56px;
}

.tabs-wrapper{
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 16px;
isolation: isolate;
width: 100%;
height: 40px;
flex: none;
order: 0;
align-self: stretch;
}

.shelf-box-shadow{
    box-sizing: border-box;
position: absolute;
height: 0px;
left: 0px;
right: 0px;
bottom: 0px;
border: 1px solid rgba(0, 0, 0, 0.03);
flex: none;
order: 0;
align-self: stretch;
}

.tabs-groups{
    display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 32px;
width: 253px;
height: 55px;
background: rgba(255, 255, 255, 1e-05);
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
z-index: 1;
}

.right-extra{
    box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
width: 400px;
height: 32px;
background: #FFFFFF;
flex: none;
order: 2;
flex-grow: 0;
z-index: 2;
}

.right-wrapper{
    display: flex;
flex-direction: row;
align-items: flex-start;
margin-right: 10px;
gap: 10px;
width: 264px;
height: 32px;
}

.skuSearchInput{
    width: 20%;
    width: auto;
    margin-right: 10px;
}

.addUserBtn{
    margin-right: 10px;
}