.on-hover-actions {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
}
.team-node-label {
  font-size: 12px;
  font-weight: 500;
}
.team-node {
  display: flex;
  align-items: center;
}
.react-flow__node-default:hover .on-hover-actions {
  display: block;
}
.react-flow__node-default {
  padding: 0px !important;
  border: none !important;
  background-color: transparent;
  box-shadow: none !important;
  z-index: 0;
}
.team-icon-wrapper {
  width: 32px;
  height: 32px;
  border: 1px solid #0f2a6f;
  padding: 8px;
  border-radius: 8px;
  margin-right: 12px;
}
.team-node-wrapper {
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 12px;
  width: calc(100% - 15px);
  padding: 8px;
}
