.input-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* width: 500px; */
  height: 82px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  height: auto;
  margin-bottom: 15px;
}

.text-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* width: 500px; */
  /* height: 80px; */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-frame-flex{
  display: flex;
}

.input-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 4px;
  gap: 4px;
  /* width: 500px; */
  height: 30px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.input-text-field {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.select-dropdown {
  width: 100%;
}

.select-dropdown .ant-select {
  width: 100%;
}

.select-date {
  width: 100%;
}

.select-date .ant-picker {
  width: 100%;
}

.error-label{
  color: red;
  margin-bottom: 15px;
  font-size: 13px;
}
.info-label {
  color: grey;
}

.radio-frame {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* width: 500px; */
  min-height: 81px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 15px; */
}

.date-frame {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* width: 500px; */
  height: 85px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.d-label{
  display:flex;
}

.promoLoading{
    margin: 20px 0;
    text-align: center;
    background: transparent;
    border-radius: 4px;
    width: 100%;
}

.promoLoading .ant-spin-dot-item {
  background: rgb(15, 42, 111);
}

.promoLoading .ant-spin-text {
  color: rgb(15, 42, 111);
  font-size: 16px;
  margin-top: 5px;
}

.promoTypeElement-container {
  margin-bottom: 15px;
  height:'auto';
}
  
.promoTypeElement-question-container {
  margin-top: 15px;
}