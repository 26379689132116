.snapshot-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
}

.snapshot-head{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.snapshotTitleWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
}

.snapshotTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.snapshotTitleText{
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
}

.snapshotFilterGrp{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 28px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.snapshot-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 100%;
    background: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.snapshotListWrapper{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.snapshotSingleListWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.snapshotSingleList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 0px 8px;
    gap: 180px;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #F5F5F5;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.snapLeftDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.snapListTitleText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.snapListSubTitleText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.snapRightDiv{
display: flex;
flex-direction: column;
align-items: flex-end;
padding: 0px;
gap: 4px;
height: 20px;
flex: none;
order: 1;
flex-grow: 0;
}

.snapDateText{
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.snapYesterdayFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 10px 8px 0px;
    gap: 10px;
    width: 81px;
    height: 44px;
}

.snapYesterdayText{
    width: 71px;
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
}

.snapPopWrapper{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: relative;
    width: 278px;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}

.snapPopHead{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
    width: 278px;
    height: 40px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.snapPopHeadTitleWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    width: 246px;
    height: 40px;
}

.snapPopFilterText{
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
}

.snapPopBody{
display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    width: 278px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.snapPopSearchBtn .ant-input-group-addon>button,
.snapPopSearchBtn .ant-input-group-addon>button:hover,
.snapPopSearchBtn .ant-input-group-addon>button:focus,
.snapPopSearchBtn .ant-input-group-addon>button:visited {
    background-color: transparent;
    border-color: #D9D9D9;
    color: rgba(0, 0, 0, 0.45);
}

.snapDatePickerDiv{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 246px;
    height: fit-content;
    flex: none;
    flex-grow: 0;
}

.snapDatePickerText{
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 2px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
}

.snapPopActions{
display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 16px;
    width: 278px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.snapPopCancelBtn{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    width: 115px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 1;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.snapPopApplyBtn{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    width: 115px;
    height: 32px;
    background: #0F2A6F;
    box-shadow: inset -1px 0px 0px #0F2A6F;
    border-radius: 2px;
    border-color: #0F2A6F;
    flex: none;
    order: 1;
    flex-grow: 1;
    color: #FFFFFF;
    cursor: pointer;
}

.snapPopSelect{
    width: 100%;
}

.snapshotTipDiv {
    margin: 20px 0;
    padding: 30px 50px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}

.snapshotTipDiv .ant-spin-dot-item {
    background: rgb(15, 42, 111);
}

.snapshotTipDiv .ant-spin-text {
    color: rgb(15, 42, 111);
    font-size: 16px;
    margin-top: 5px;
}

.snapshotWholeWrapper{
    height: calc(100vh - 101px);
    overflow: auto;
}

.snapshotScrollbarStyle{
    height: calc(100vh - 101px);
    width: 100%;
}

.snapshotFilterGrp .ant-select{
    width: 130px;
}

.mt-10{
margin: 10px 0;
}

@media only screen and (max-width: 850px) {
    .snapshot-head {
        width: 800px;
    }
    .snapshot-body {
        width: 800px;
    }
}