.container {
  min-height: calc(100dvh - 104px);
  display: flex;
  gap: 24px;
  flex-direction: column;
  .body_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .field_label {
      font-size: 14px;
    }
    .fields_container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .error_message {
      font-size: 12px;
      color: red;
      padding-left: 12px;
    }
  }

  .footer_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      all: unset;
      padding: 6px 12px;
      align-items: center;
      display: flex;
      text-align: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }

    .edit_button {
      color: #0f2a6f;
    }
    .action_container {
      display: flex;
      gap: 12px;

      .download_csv {
        background-color: #0f2a6f;
        color: white;
        &:disabled {
          background-color: gray;
        }
      }
      .save {
        color: black;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        &:disabled {
          background-color: gray;
        }
      }
    }
  }
}

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
