.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  top: 0px;
  left: 0px;
  z-index: 9999;
  &__lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 64px;
    margin: auto;
    height: 64px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #0f2a6f;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &__lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  &__lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &__lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &__lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}
