@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
}

.form {
  padding: 0px 0px;
}

.logo-petco {
  margin-bottom: 30px;
}

.addUserBtnAuth {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
  height: 40px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.addUserBtnAuth:hover,
.addUserBtnAuth:visited,
.addUserBtnAuth:focus {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
  color: white;
}

.head-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px;
  height: 46px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.ant-alert {
  width: 100%;
}

.ant-input-affix-wrapper > input.ant-input {
  width: 312px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2px;
}

.remember-text {
  width: 92px;
  height: 22px;
}

.line-only {
  box-sizing: border-box;

  width: 360px;
  height: 0px;

  mix-blend-mode: darken;
  border: 1px solid rgba(0, 0, 0, 0.03);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.forgot-text {
  width: 163px;
  height: 24px;
  margin-left: 71px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #0f2a6f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.ant-input-affix-wrapper {
  width: 360px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.item-count-forgot {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 100px;
  height: 46px;
  flex: none;
  order: 0;
}

.item-count-reset {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 126px;
  height: 46px;
  flex: none;
  order: 0;
}

.item-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 35px;
  height: 46px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.item-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 35px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.item-tab-forgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.item-tab-reset {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 126px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tab1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 10px;
  width: 35px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.tab1-forgot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 10px;
  width: 100px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.tab1-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 10px;
  width: 126px;
  height: 32px;
}

.text-mode {
  width: 35px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  color: #0f2a6f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-mode-forgot {
  width: 100px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #0f2a6f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-mode-reset {
  width: 126px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #0f2a6f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.ink {
  box-sizing: border-box;
  font-weight: bold;
  width: 35px;
  height: 0px;
  border: 0.8px solid #0f2a6f;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.ink-forgot {
  box-sizing: border-box;
  font-weight: bold;
  width: 90px;
  height: 0px;
  border: 0.8px solid #0f2a6f;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.ink-reset {
  box-sizing: border-box;
  font-weight: bold;
  width: 116px;
  height: 0px;
  border: 0.8px solid #0f2a6f;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.error-label {
  color: red;
}
.info-label {
  color: grey;
}

.display-login {
  display: flex;
}

.ant-input-prefix {
  margin-right: 12px;
}

.check-style {
  width: max-content;
  margin-bottom: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.reset-box {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 360px;
  height: 80px;
}

.col-reset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 166px;
  height: 68px;
}

.green {
  color: green;
}

.one-reset {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
  gap: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 166px;
  height: 20px;
}

.back-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 22px;
  gap: 8px;
  width: 360px;
  height: 40px;
  border-radius: 2px;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin-top: 8px;

  color: #0f2a6f;
}

.back-login-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0f2a6f;
  margin-left: 8px;
}

.links {
  color: #0f2a6f;
}

.ant-table-filter-dropdown .ant-space-item .ant-btn-primary {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.map-input::-webkit-outer-spin-button,
.map-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.map-input[type="number"] {
  -moz-appearance: textfield;
}
.ant-btn.btn-primary {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
  color: white;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn.btn-secondary {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.ant-btn.btn-secondary:hover {
  color: 40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.ant-btn.btn-primary:hover {
  color: white;
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
}

.version {
  position: absolute;
  right: 7px;
  bottom: 30px;
  font-style: italic;
  color: grey;
}