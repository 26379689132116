.create-footer {
  color: #0f2a6f;
}
.next-btn {
  margin-left: 8px;
  background-color: #0f2a6f;
  color: white;
}
.create-footer .ant-btn-link {
  color: inherit;
}
.teams-wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: auto;
  
}
.create-promo-new .ant-select{
  margin-top: 4px;
}
.teams-wrapper::-webkit-scrollbar {
  width: 4px;
}
.teams-wrapper::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
  visibility: hidden;
}
.teams-wrapper::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
  visibility: hidden;
}
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
.teams-wrapper:active::-webkit-scrollbar-track,
.teams-wrapper:focus::-webkit-scrollbar-track,
.teams-wrapper:hover::-webkit-scrollbar-track {
  visibility: visible;
}

.teams-wrapper:active::-webkit-scrollbar-thumb,
.teams-wrapper:focus::-webkit-scrollbar-thumb,
.teams-wrapper:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.team-item {
  min-width: 200px;
  color: #49454f;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
}
.active-border {
  display: none;
}
.team-item.active {
  background-color: #eef9ff;
  color: #0f2a6f;
}
.team-item .team-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.team-item.active .active-border {
  height: 4px;
  background-color: #0f2a6f;
  border-radius: 600px 600px 0px 0px;
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.line-break {
  flex-basis: 100%;
  height: 20px;
}

.option-item {
  width: calc(50% - 8px); /* Adjust the width and gap */
  height: 50% !important;
}

.option-item.has-margin {
  margin-left: 16px; /* Apply margin to every second element */
}
