.ant-layout-sider{
    background: white !important;
}

.ant-layout-sider-trigger{
    background: white !important;
    color: black !important;
    border-top: 1px solid rgb(240, 240, 240) !important;
}

.layout-ht{
    min-height: 100vh !important;
}

.content-mr{
    margin: 16px;
}

.no-content-mr{
    margin: 0px;
}