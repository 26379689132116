.box-container {
    display: flex;
}

.right-box {
    /* width: 22%; */
    width: 209px;
    background: #FFF;
    padding: 16px 28px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-left: 1px solid #EAEAEA;
}

.left-box {
    width: 100%;
    background: #FFF;
}

.right-box-container {
    /* display: flex;
    flex-direction: column; */

}

.month-changer {
    display: flex;
    justify-content: space-between;
}
.left-icon {
    margin-right: 16px;
}
.adset-wrapper {
    padding-top: 16px;
    border-top: 1px solid #EAEAEA;
    padding-left: 14px;
}
.adset-wrapper-label {
    color: #979797;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
   
 }
 .adset-name {
    border-radius: 2px;
    background: #EEF0F4;
    /* display: flex; */
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;
 }
 .event-name {
    border-radius: 2px;
    background: #EEF4FF;
    /* display: flex; */
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;
 }
 .adset-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 500px;
    overflow-y: scroll;
 }
 .w-152 {
    width: 152px;
 }

 .date-range-label {
    color: #A0A0A0;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
 }