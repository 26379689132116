.add-error-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 8px;
    height: 88px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.error-right-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 375px;
    height: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ant-modal-body {
    max-height: 45rem !important;
    overflow-y: scroll;
}

.searchErrorBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 350px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.searchErrorBtn .ant-input-group-addon>button,
.searchErrorBtn .ant-input-group-addon>button:hover,
.searchErrorBtn .ant-input-group-addon>button:focus,
.searchErrorBtn .ant-input-group-addon>button:visited {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
}

.left-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: fit-content;
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.addErrorBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: fit-content;
    height: 40px;
    background: #0F2A6F;
    border: 1px solid #0F2A6F;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
}

.addErrorBtn:hover,
.addErrorBtn:visited,
.addErrorBtn:focus {
    background: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
}

.error-table-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    margin-top: 16px;
    background: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.error-table-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px 16px;
    gap: 10px;
    height: 48px;
    background: rgba(255, 255, 255, 1e-05);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.errorbtnGrpWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 256px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.errorFilterGrpWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: fit-content;
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.filterGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 188px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.settingGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 72px;
    height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.btnStyle {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: rgb(15, 42, 111);
    background: rgb(15, 42, 111);
    color: white;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btnStyle1 {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
}

.btnStyle1:hover {
    color: 40a9ff;
    border-color: #40a9ff;
    background: #fff;
}

.btnStyle:hover {
    color: white;
    border-color: rgb(15, 42, 111);
    background: rgb(15, 42, 111);
}

.spanReq {
    width: 7px;
    height: 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #FF4D4F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-25 {
    margin-bottom: 25px;
}

.datePic {
    display: block;
}

.errorLabel {
    color: #FF4D4F;
    font-size: 12px;
    font-weight: bold;
}

.w-100 {
    width: 100%;
}

.highClr {
    color: #F5222D;
}

.lowClr {
    color: #52C41A;
}

.mediumClr {
    color: #FAAD14;
}

.footerBtnNavy {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
    color: white;
}

.footerBtnNavy,
.footerBtnNavy:hover,
.footerBtnNavy:focus,
.footerBtnNavy:active {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
    color: white;
}

.footerBtnNavy[disabled],
.footerBtnNavy[disabled]:hover,
.footerBtnNavy[disabled]:focus,
.footerBtnNavy[disabled]:active {
    background-color: rgba(15, 42, 111, 1);
    border-color: rgba(15, 42, 111, 1);
    color: white;
}


.modalContentDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px;
    gap: 10px;
    height: 281px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 10px;
}

.desctitleText {
    height: 43px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.descriptionDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dessubTitleText {
    margin-top: 5px;
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.ticketId {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
}

.successIconStyle {
    font-size: 56px;
    background-color: rgba(82, 196, 26, 1);
    border-radius: 50%;
}

.failIconStyle {
    font-size: 56px;
    color: rgba(255, 204, 199, 1)
}

.mt-30 {
    margin-top: 30px;
}

.errorTipDiv {
    margin: 20px 0;
    padding: 30px 50px;
    text-align: center;
    background: transparent;
    border-radius: 4px;
}

.errorTipDiv .ant-spin-dot-item {
    background: rgb(15, 42, 111);
}

.errorTipDiv .ant-spin-text {
    color: rgb(15, 42, 111);
    font-size: 16px;
    margin-top: 5px;
}

@media only screen and (max-width: 840px) {
    .add-error-card {
        flex-direction: column;
        height: auto;
    }
}

@media only screen and (max-width: 720px) {
    .error-right-content {
        width: fit-content;
    }

    .error-right-content .ant-input-group-wrapper {
        width: auto;
    }

    .error-right-content .ant-input-wrapper {
        width: auto;
    }

    .error-right-content .ant-input-affix-wrapper {
        width: auto;
    }

    .error-right-content .ant-input-affix-wrapper>input.ant-input {
        width: auto;
    }
}

@media only screen and (max-width: 400px) {

    .error-right-content .ant-input-affix-wrapper>input.ant-input {
        width: 120px;
    }
}

@media only screen and (max-width: 550px) {
    .error-table-topbar {
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .errorbtnGrpWrapper {
        width: auto;
    }
}

.ant-select-item-option-content {
    overflow: visible !important;
    white-space: break-spaces !important;
}