.logo {
    height: 101px;
    background: rgba(0, 25, 82, 1);
    position: relative;
}

.imgStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    width: 121.9px;
    height: 52px;
    left: calc(50% - 121.9px/2 - 0.36px);
    top: calc(50% - 52px/2 + 0.5px);
}

.ant-menu-root {
    padding-top: 20px !important;
}

.sider-trigger {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    height: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.imgStyle-collapsed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    width: auto;
    height: 30px;
    left: 5px;
    top: 30px;
}

.logoutIconStyle {
    color: rgba(255, 77, 79, 1);
    cursor: pointer;
}

.logoutBtnStyle {
    color: rgba(255, 77, 79, 1) !important;
}

.logoutBtnStyle:hover {
    color: rgba(255, 77, 79, 1);
}

.sidebarMenuStyle{
    height: calc(100vh - 141px);
    overflow-y: auto;
    overflow-x: hidden;
}