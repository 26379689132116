.footer{
    display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;  
  position: absolute;
  width: 334px;
  height: 22px;
  left: calc(50% - 334px/2 - 0.5px);
  bottom: 27px;
  }
  
  .footer-content{
    width: 334px;
  height: 22px;  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  flex: none;
  order: 0;
  flex-grow: 0;
  }
  
  