.add-user-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 8px;
  height: 88px;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f0f0f0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.right-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 375px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.userErrorBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 350px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.left-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: fit-content;
}

.addUserBtn {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.addUserBtn:hover,
.addUserBtn:visited,
.addUserBtn:focus {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.user-table-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin-top: 16px;

  /* width: 1184px; */
  /* height: 648px; */
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.table-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 16px;
  gap: 10px;
  height: 48px;
  background: rgba(255, 255, 255, 1e-5);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.btnGrpWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 256px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.shelf-right-contents {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 375px;
  height: 40px;
}

.shelf-left-contents {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 286px;
  height: 40px;
}

.filterGrpWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 276px;
  height: 32px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.filterGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 188px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.settingGrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-left: 12px;
  gap: 12px;
  width: 72px;
  height: 16px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.title-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;
  width: 95px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.icon-wrapper {
  width: 14px;
  height: 25px;
}

.union {
  position: absolute;
  width: 12.22px;
  height: 12px;
  left: 0.56px;
  top: 1px;
  background: rgba(0, 0, 0, 0.85);
}

.export {
  position: absolute;
  width: 12.22px;
  height: 12px;
  left: 0.56px;
  top: 1px;
  background: rgba(0, 0, 0, 0.85);
}

.vector {
  position: absolute;
  left: 7.14%;
  right: 5.59%;
  top: 7.13%;
  bottom: 7.16%;
  background: rgba(0, 0, 0, 0.25);
}

.export-text {
  width: 41px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.spanReq {
  width: 7px;
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #ff4d4f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mt-5 {
  margin-top: 5px;
}

.passwordSpan {
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 13px;
}

.ant-modal-body form .ant-radio-checked .ant-radio-inner {
  border-color: rgba(15, 42, 111, 1);
}
.ant-modal-body form .ant-radio-inner::after {
  background: rgba(15, 42, 111, 1);
}

.ant-modal-confirm-btns .ant-btn-primary {
  background: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
}

.errorLabel {
  color: #ff4d4f;
  font-size: 12px;
  font-weight: bold;
}

.btnStyle {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
  color: white;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btnStyle1 {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.btnStyle1:hover {
  color: 40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.btnStyle:hover {
  color: white;
  border-color: rgb(15, 42, 111);
  background: rgb(15, 42, 111);
}

.validationList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  height: 68px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.listCol1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 68px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 25px;
}

.listCol2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 44px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-25 {
  margin-bottom: 25px;
}

.label-grey {
  color: rgba(0, 0, 0, 0.45);
}

.w-100 {
  width: 100% !important;
}

.checkGreen {
  color: #52c41a !important;
}

.passGreen {
  color: #52c41a;
}

.failGrey {
  color: rgba(0, 0, 0, 0.45);
}

.footerBtnNavy {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
  color: white;
}
.footerBtnNavy,
.footerBtnNavy:hover,
.footerBtnNavy:focus,
.footerBtnNavy:active {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
  color: white;
}
.footerBtnNavy[disabled],
.footerBtnNavy[disabled]:hover,
.footerBtnNavy[disabled]:focus,
.footerBtnNavy[disabled]:active {
  background-color: rgba(15, 42, 111, 1);
  border-color: rgba(15, 42, 111, 1);
  color: white;
}

.alertStyle {
  display: flex;
  flex-direction: row;
  padding: 9px 16px;
  gap: 10px;
  align-items: center;
  position: absolute;
  width: 395px;
  height: 40px;
  left: calc(50% - 395px / 2 + 0.5px);
  top: 40px;
}

.ant-switch-checked.switchStyle {
  background: rgba(15, 42, 111, 1);
}

.mt-16 {
  margin-top: 16px;
}
