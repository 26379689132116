.mapBodyTop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    background: #FFFFFF;
    width: 100%;
    /* height: 227px; */
}

.mapBodyTopBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 8px;

    width: 100%;
    height: 88px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.mapBodyTopSummary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    overflow-x: auto;
}

.mapBodyTopRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    height: 40px;
}

.mapBodyTopLeft {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    height: 40px;
}

.mapBodyTopSummaryHeadingText {
    width: 106px;
    height: 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.mapBodyTopSummaryWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 0px;

    width: 100%;
    height: 71px;
}

.end {
    text-align: end;
}

.mr2 {
    margin-right: 20px;
}

.mapCreateBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 10px;
    /* height: 792px; */
    background: #FFFFFF;
}

.mapTableWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    margin: 16px;
    background: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.map-table-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px 16px;
    gap: 10px;
    height: 48px;
    background: rgba(255, 255, 255, 1e-05);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.mapbtnGrpWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* width: 256px; */
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mapFilterGrpWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: fit-content;
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.filterGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 188px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mapsettingGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 72px;
    height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.text-end {
    text-align: end;
}

.map-create-card {
    padding: 24px;
    gap: 8px;
    background: #ffffff;
    box-shadow: inset 0px 1px 0px #f0f0f0;
}

.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tb-bg {
    background-color: rgba(255, 77, 79, 1);
    color: white
}

.tb-bg-warning {
    background-color: #ffe58f; 
    color: black
}

.map-table .ant-table-tbody>tr.ant-table-row:hover>td,
.map-table .ant-table-tbody>tr.ant-table-cell-row:hover {
    background-color: unset;
}

.ml-10 {
    margin-left: 10px;
}

.flex-zero{
    flex-grow: 0;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.f-w{
    font-weight: bold;
}

.datePickerDiv .ant-picker,
.dropDownDiv .ant-select,
.inputDiv .ant-input,
.textareaDiv .ant-input{
    width: 150px;
}

.textareaDiv{
    margin-top: 10px;
}

.mapPriceUpdateDiv .ant-select{
    width: 80px;
}

.mapPriceLoadDiv .ant-input{
    width: 100px;
}

@media only screen and (max-width: 1000px) {
    .mapBodyTopBtn {
        flex-direction: column;
        height: auto;
    }
}

@media only screen and (max-width: 800px) {
    .mapBodyTopRight {
        flex-direction: column;
        height: auto;
    }
}

@media only screen and (max-width: 700px) {
    .map-table-topbar {
        flex-direction: column;
        height: auto;
    }
}

@media only screen and (max-width: 350px) {
    .mapbtnGrpWrapper {
        flex-direction: column;
        height: auto;
    }
}