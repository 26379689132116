body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fc-col-header-cell-cushion {
  color: #989898;

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.fc-daygrid-day-number {
  color: #989898;

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}
.event-view {
  background-color: #eef4ff;
  border: 1px solid #eef4ff;
  border-radius: 2px;
  color: #000 !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.adset-view {
  background-color: #eef0f4;
  border: 1px solid #eef0f4;
  border-radius: 2px;
  color: #000 !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.fc-h-event .fc-event-main {
  color: #000 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #fff;
  .fc-daygrid-day-frame {
    .fc-daygrid-day-top {
      a {
        border-radius: 2px;
        border: 1px solid #001952;
        line-height: 20px;
      }
    }
  }
}

.fc .fc-daygrid-day-bottom {
  margin: 0px;
}

.fc .fc-daygrid-more-link {
  background: #90a4b7;
  border: 1px solid #90a4b7 !important;
  color: #90a4b7;
  height: 6px;
  padding: 0;
  border-radius: 0;
  width: 100%;
}

.fc-col-header-cell {
  border-bottom: none !important;
}

.fc .fc-daygrid-more-link:hover {
  background-color: #90a4b7 !important;
}
.flex {
  display: flex;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.w-full {
  width: 100%;
}
.h-full{
  height: 100%;
}
.width-50 {
  width: 50%;
}
.gap-16 {
  gap: 16px;
}
.top-padding{
  padding-top: 10px;
}
.ant-timeline-item-head-custom {
  padding: 0 !important;
}

.ant-timeline-item-content {
  top: -20.001px !important;
}
.ant-radio-button-wrapper{
  text-align: center;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-color: rgb(15, 42, 111) !important;
  background: rgb(15, 42, 111) !important;
  color: white !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
