.clickable {
    cursor: pointer;
}

.btn-style{
    border: none;
    background: transparent;
}

.mr-5{
    margin-right: 5px;
}

.assign{
    width: 100%;    
}

.pag{
    width: 100%;
}

.action-btns-container{
    display: flex;
}
.new-table .ant-spin-dot-item{
  background: rgb(15, 42, 111);
}
.custom_button{
    padding-right: 8px;
    padding-left: 8px;
}